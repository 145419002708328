<template>
  <router-link :to="params.link"
               class="plot">
    <div class="plot__wrapper">
      <div class="plot__image-holder">
        <img :src="params.images[0]"
             alt=""
             class="plot__image"/>
      </div>
      <div class="plot__type plot__type-green">type</div>
      <div class="plot__title">{{params.title}}</div>
    </div>
  </router-link>
</template>

<script>
export default {
  name: 'Plot',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
};
</script>
